import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { reportBeforeEach, mmsBeforeEach } from '@/router/hook/guard'

import { RouterName } from '@/router/enum'

// NOTE: FCM(JQPR / REPORT)
const ReleaseReport = () => import('@/pages/assistant/release/ReleaseReport.vue')

// NOTE: MMS
const ReleaseMMS = () => import('@/pages/assistant/release/ReleaseMMS.vue')

// NOTE: NoPage
const NoPage = () => import('@/pages/404.vue')

/**
 *
 * @라우터
 */

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RouterName.NoPage },
  },
  {
    path: '/',
    redirect: { name: RouterName.NoPage },
  },
  /**
   *
   * @JQPR
   * @REPORT
   */
  {
    name: RouterName.FCMAssistant,
    path: '/release-assistant',
    component: ReleaseReport,
    props: (route) => route.query,
    beforeEnter: reportBeforeEach,
  },
  /**
   *
   * @MMS
   */
  {
    name: RouterName.MMSAssistant,
    path: '/assistant-chat/:assistantId/:chatId',
    component: ReleaseMMS,
    props: true,
    beforeEnter: mmsBeforeEach,
  },
  {
    name: RouterName.NoPage,
    path: '/404',
    component: NoPage,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
