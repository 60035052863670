import { createApp } from 'vue'

import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import Vue3Lottie from 'vue3-lottie'

import { useAlert } from './composables/alert'
const { error, confirm } = useAlert()

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(Vue3Lottie)
app.use(createVuestic({ config: vuesticGlobalConfig }))

app.provide('$confirm', confirm)
app.provide('$error', error)

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
