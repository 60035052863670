import type { NavigationGuardWithThis } from 'vue-router'
import type { FCMType } from '@/pages/assistant/interface'
import { TOKEN, UUID } from '@/auth/token'
import { RouterName } from '@/router/enum'
import { MMSId } from '@/stores/types/appChat'

const validFCMTypes: FCMType[] = ['report', 'jqpr']

/** ## [ 협의록 & JQPR 네비게이션 가드 ] */
const reportBeforeEach: NavigationGuardWithThis<undefined> = (to, _, next) => {
  // NOTE: 진입 라우트의 Query
  const { uuid, authToken, pCode } = to.query

  // NOTE: uuid, authToken, pCode 셋 중 하나라도 없다면 진입 불가
  if (!uuid || !authToken || !pCode) {
    next({ name: RouterName.NoPage })
  }

  // NOTE: pCode가 올바른 값이 아니라면 진입 불가
  if (!validFCMTypes.includes(pCode as FCMType)) {
    next({ name: RouterName.NoPage })
  }

  UUID.SET(uuid as string)
  TOKEN.SET(authToken as string)

  next()
}

const mmsBeforeEach: NavigationGuardWithThis<undefined> = (to, _, next) => {
  const { assistantId, chatId } = to.params

  // NOTE: 만약 assistantId가 MMSId에 없다면 RETURN)
  const targets: MMSId[] = ['mmsassistant_all', 'mmsassistant_direct', 'assistant_guide']
  if (!targets.includes(assistantId as MMSId)) next({ name: RouterName.NoPage })

  if (!assistantId || !chatId) next({ name: RouterName.NoPage })

  next()
}

export { reportBeforeEach, mmsBeforeEach }
